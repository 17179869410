import { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { useLocation, useNavigate, useSearchParams } from '@remix-run/react';

import { PRODUCT_FILTERS } from '@modules/product/config/product-filter-config';
import { PROJECT_FILTERS } from '@modules/project/config/project-filter-config';
import { PROFESSIONAL_FILTERS } from '@modules/professional/config/professional-filter-config';
import { ARTICLE_FILTERS } from '@modules/articles/config/article-filter-config';

import type { FilterSection } from '../components/filter-drawer/FilterDrawer';
import type {
    FilterState,
    FilterStates,
    FilterStatesKey,
} from '../type/filter-state';

import type {
    ElasticSearchCategories,
    ElasticSearchSortOptions,
} from '../type/api';
import { getAppliedFilterItems } from '../util/get-applied-filter-items';
import { getFilterBarFilters } from '../util/get-filter-bar-filters';
import { useTracker } from '@archipro-website/tracker';
import { useAggregation } from './use-aggregation';
import { trackAppliedFilter } from '~/modules/tracking/util/trackAppliedFilter';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import { createFacetFilterSections } from '../util/create-facet-filters';
import { generateURLByFilterState } from '../util/filter-url-helpers';
import { useUser } from '~/modules/user';

const BLOCK_PROFESSIONAL_CATEGORIES = [
    '/professionals/product-supplier',
    '/professionals/industry-associations-certifications',
];

const ROOT_FILTERS: FilterSection[] = [
    {
        title: 'Pricing',
        filter: {
            kind: 'FilterGroup',
            key: 'pricingGroup',
            gap: 'space-36',
            filters: [
                {
                    kind: 'FilterGroup',
                    key: 'pricingGroup',
                    gap: 'space-8',
                    filters: [
                        {
                            kind: 'Checkbox',
                            filterKey: 'isPurchasable',
                            props: {
                                label: 'Shop',
                            },
                            displayOnFilterBar: true,
                            displayOnMobileFilterBar: true,
                            filterBarOrder: 0,
                        },
                    ],
                },
            ],
        },
    },
    {
        title: 'Specify',
        filter: {
            kind: 'SearchList',
            filterKey: 'downloads',
            props: {
                className: 'downloadSearchList',
                items: [],
                children: () => <div />,
                hideSearch: true,
                subtext: 'Product must include:',
            },
            displayOnFilterBar: true,
            filterBarOrder: 1,
            filterBarTitle: 'Specify',
            displayOnMobileFilterBar: true,
        },
    },
];

const SEARCH_FILTERS: Record<
    ElasticSearchCategories,
    FilterSection[] | undefined
> = {
    product: PRODUCT_FILTERS,
    project: PROJECT_FILTERS,
    professional: PROFESSIONAL_FILTERS,
    blogpost: ARTICLE_FILTERS,
};

interface SearchFilterOptions {
    category?: ElasticSearchCategories;
    initialStates: FilterStates | null;
    isCategoryPage?: boolean;
    ignoreFilterKeys?: FilterStatesKey[];
}

export const USER_PREFERENCE_KEY = 'updateUserPrefs';

export const filterProfessionalCategory = (states: FilterStates) => {
    const categoryFilter = states.category;
    if (categoryFilter) {
        categoryFilter.category.children =
            categoryFilter.category.children?.filter(
                (child) => !BLOCK_PROFESSIONAL_CATEGORIES.includes(child.id)
            );
    }
};

const useSearchFilters = ({
    category,
    initialStates,
    isCategoryPage,
    ignoreFilterKeys,
}: SearchFilterOptions) => {
    const [filterStates, setFilterStates] = useState<FilterStates>(
        initialStates ?? {}
    );

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { log } = useTracker();
    const { aggregate } = useAggregation({ category });
    const { desktop: isDesktop, mobile: isMobile } = useAppDisplayModeContext();
    const tracker = useTracker();
    const user = useUser();

    let filters = category ? SEARCH_FILTERS[category] : ROOT_FILTERS;

    const refetchData = useCallback(
        (states: FilterStates, updateUserPrefs: boolean) => {
            const originalURL = location.pathname + location.search;
            const newURL = generateURLByFilterState(
                states,
                originalURL,
                user.FeatureFlags,
                ignoreFilterKeys,
                updateUserPrefs
            );

            if (newURL === originalURL) return;
            // applied filters change, reload data
            navigate(newURL, { preventScrollReset: true });
        },
        [location, ignoreFilterKeys, navigate, user]
    );

    useUpdateEffect(() => {
        // check only associations params after the data is reloaded and url is updated
        if (searchParams.has('associations')) {
            log('AssociationFilter', {
                url: new URL(window.location.href),
            });
        }
    }, [location]);

    useUpdateEffect(() => {
        setFilterStates(initialStates ?? {});
    }, [initialStates]);

    const resetFilters = useCallback(
        (states?: FilterStates) => {
            const newStates = states ?? {};
            setFilterStates(newStates);
            refetchData(newStates, category === 'product' && !!isCategoryPage);
        },
        [category, isCategoryPage, refetchData]
    );

    const updateFilter = useCallback(
        <T extends FilterState>(
            filterKey: FilterStatesKey,
            state: Partial<T>,
            updateStates = true,
            subFilterKey?: string
        ) => {
            const getPreState = (states: FilterStates) => {
                if (filterKey !== 'facetOptions') return states[filterKey];
                const facetStates = filterStates[filterKey];
                return facetStates?.find(
                    (facetState) => facetState.id === subFilterKey
                );
            };

            let prevStateForTrack = getPreState(filterStates);

            if (
                isDesktop &&
                !!prevStateForTrack &&
                'kind' in prevStateForTrack
            ) {
                // event tracking for side menu filters.
                trackAppliedFilter(tracker, location, state, prevStateForTrack);
            }

            setFilterStates((preStates) => {
                const currentState = preStates[filterKey];
                let newStates: FilterStates;
                if (filterKey !== 'facetOptions') {
                    newStates = {
                        ...preStates,
                        [filterKey]: currentState
                            ? { ...currentState, ...state }
                            : state,
                    };
                } else {
                    const facetStates = filterStates[filterKey];
                    if (!facetStates || !facetStates.length) return preStates;
                    const facetOptionState = facetStates.find(
                        (facetState) => facetState.id === subFilterKey
                    );
                    if (!facetOptionState) return preStates;
                    facetOptionState.state = {
                        ...facetOptionState.state,
                        ...state,
                    };
                    newStates = {
                        ...preStates,
                        facetOptions: [...facetStates],
                    };
                }

                if (updateStates)
                    refetchData(
                        newStates,
                        filterKey === 'isPurchasable' &&
                            category === 'product' &&
                            !!isCategoryPage
                    );
                return newStates;
            });
        },
        [
            category,
            isCategoryPage,
            refetchData,
            isDesktop,
            location,
            filterStates,
            tracker,
        ]
    );

    const updateSort = useCallback(
        (sort?: ElasticSearchSortOptions) => {
            if (!filterStates || filterStates.sortBy === sort) return;
            filterStates.sortBy = sort;
            const newFilterStates = {
                ...filterStates,
                sortBy: sort,
            };
            setFilterStates(newFilterStates);
            refetchData(newFilterStates, false);
        },
        [filterStates, refetchData]
    );

    const appliedFilters = useMemo(() => {
        if (!filters || !filterStates) return undefined;
        const facetSections = filterStates.facetOptions
            ? createFacetFilterSections(filterStates.facetOptions)
            : [];
        return getAppliedFilterItems(
            [...filters, ...facetSections],
            filterStates,
            updateFilter,
            ignoreFilterKeys
        );
    }, [filters, filterStates, updateFilter, ignoreFilterKeys]);

    const filterBarFilters = useMemo(() => {
        if (!filters || !filterStates) return undefined;
        return getFilterBarFilters(filters, filterStates, isMobile);
    }, [filters, filterStates, isMobile]);

    if (category === 'professional') filterProfessionalCategory(filterStates);
    return {
        filters,
        filterStates: filterStates
            ? {
                  ...filterStates,
                  isPurchasable: filterStates.isPurchasable
                      ? {
                            ...filterStates.isPurchasable,
                            disabled: filterStates.isPurchasable.disabled,
                        }
                      : undefined,
              }
            : filterStates,
        appliedFilters,
        filterBarFilters: filterBarFilters,
        ignoreFilterKeys,
        updateFilter,
        resetFilters,
        updateSort,
        aggregate,
    };
};

export { useSearchFilters };
