import type { CategoryFilterState } from '@modules/search/components/filter-drawer/FilterCategorySelector';
import type { CheckboxFilterState } from '@modules/search/components/filter-drawer/FilterCheckbox';
import type { CheckboxGroupFilterState } from '@modules/search/components/filter-drawer/FilterCheckboxGroup';
import type { RangeSelectorFilterState } from '@modules/search/components/filter-drawer/FilterRangeSelector';
import type { SearchListFilterState } from '@modules/search/components/filter-drawer/FilterSearchList';
import type { LocationFilterState } from '@modules/search//components/filter-drawer/FilterLocation';
import type { RadioGroupFilterState } from '../components/filter-drawer/FilterRadioGroup';
import type {
    BusinessFocusType,
    CheckboxFilterKeys,
    ElasticSearchCategories,
    ElasticSearchQueryFilters,
    ElasticSearchQueryProps,
    ElasticSearchSortOptions,
    FacetOptionsFilterParams,
    RangeFilterKeys,
    SearchListFilterKeys,
    SearchLocation,
} from '../type/api';
import type { FilterStates, FilterStatesKey } from '../type/filter-state';
import { DEFAULT_SEARCH_LIST_SIZE } from './create-es-aggregation-params';
import {
    LOCATION_SEARCH_PARAM_KEYS,
    SEARCH_CATEGORIES,
    SEARCH_QUERY_KEY,
} from '@modules/search/config';

import { USER_PREFERENCE_KEY } from '../hook/use-search-filters';
import { DEFAULT_PAGE_SIZE } from '../components/search-results-grid/SearchResultsGrid';
import { applyESSearchSort } from './apply-es-search-sort';
import type { URLParams } from '@archipro-website/top-navigation';
import {
    LEGACY_PATH_PARAM_KEYS,
    generateURL,
    getBoolean,
    getNumber,
    getNumberRange,
    getString,
    getStringArray,
    parseURL,
} from '@archipro-website/top-navigation';
import type { Category } from '@archipro-design/aria';
import { hasFeature, type FeatureFlags } from '~/modules/root';
import type { AwardFilterState } from '@modules/search/components/awards-filter-tree/AwardsFilterTree';

const URL_PARAM_MAP: Record<string, string> = {
    professions: 'profession',
    professionals: 'company',
    libraryRanges: 'productRanges',
    sortBy: 'sort',
    isPurchasable: 'forSale',
    category: 'categoryLink',
    state: 'location-state',
};

const RANGE_DIVISORS: Record<RangeFilterKeys, number> = {
    priceRange: 100,
    lengthRange: 1000,
    widthRange: 1000,
    heightRange: 1000,
    diameterRange: 1000,
    weightRange: 1000,
};

export const DEFAULT_SOURCE_FILTER = [
    'id',
    'ArticleWordCount',
    'Categories',
    'CompanyLogos',
    'Created',
    'Link',
    'LiveDate',
    'CanPurchase',
    'LibraryThumbs',
    'LogoBackgroundColor',
    'PType',
    'ProductColours',
    'ProfessionalID',
    'Professional',
    'ProfessionalLink',
    'Thumb_lg',
    'Title',
    'DirectoryTile',
    'Services',
    'PricingDisplay',
    'MemberPricing',
    'TradePricing',
    'SearchLocations',
    'Thumbnail',
    'Email',
    'Phone',
    'Phone2',
    'HasAwards',
    'Visibilities',
    'DirectoryTiles',
    'DownloadFileCategories',
    'SEODescription',
    'TotalViewedCount',
    'TotalSavedCount',
    'EmbeddingNMSLIB',
    'EmbeddingContent',
    'MergedContentEmbedding',
    'MergedContent',
    'ProductColours',
];

const ORDERED_LOCATION_KEY: (keyof SearchLocation)[] = [
    'suburb',
    'district',
    'city',
    'region',
    'state',
    'country',
];

const INDEX_LOCATION_FILTER_TYPES: (keyof SearchLocation)[] = [
    'city',
    'region',
    'state',
];

/**
 * Create a new URL based on the filters' state and original URL
 * @param filterStates      the filter states
 * @param originalURL
 * @param ignoreFilterKeys  we will ignore these keys when parsing the filter states
 * @param updateUserPrefs   add additional updateUserPrefs parameter if it's true
 * @returns
 */
export const generateURLByFilterState = (
    filterStates: FilterStates,
    originalURL: string,
    featureSet: FeatureFlags,
    ignoreFilterKeys: FilterStatesKey[] = [],
    updateUserPrefs = false
): string => {
    const isCategoryPage = !!SEARCH_CATEGORIES.find(
        (cfg) => cfg.categoryLink && originalURL.startsWith(cfg.categoryLink)
    );

    // we will not add the category param if it's category page
    // instead, we will create new path based on the category state
    const params = convertFilterStateToURLParams(
        filterStates,
        featureSet,
        isCategoryPage ? [...ignoreFilterKeys, 'category'] : ignoreFilterKeys
    );

    if (updateUserPrefs) {
        params[USER_PREFERENCE_KEY] = 1;
    }

    const {
        basePath,
        params: originalParams,
        isSuppliersEndpoint,
    } = parseURL(originalURL);

    // keep original info for the non category page
    if (!isCategoryPage) {
        if (originalParams?.category) {
            params.category = originalParams.category;
        } else {
            if (params.forSale || params.downloads) {
                params.category = 'products';
            }
        }
        if (originalParams?.search) {
            params.search = originalParams.search;
        }
    }

    const categoryState = filterStates.category;

    // create new path based on the category state for the category page
    const newPath = isCategoryPage
        ? categoryState?.selectedCategory?.id ?? basePath
        : basePath;

    return generateURL(
        newPath + (isSuppliersEndpoint ? '/suppliers' : ''),
        params,
        true,
        LEGACY_PATH_PARAM_KEYS,
        getPathFilterKeysByCategory(
            featureSet,
            categoryState?.selectedCategory,
            isCategoryPage
        )
    );
};

export const getPathFilterKeysByCategory = (
    featureSet: FeatureFlags,
    category?: Category | null,
    isCategoryPage = false
): Readonly<string[]> => {
    if (!isCategoryPage || !category) return [];
    const isProductCategory = category.id.startsWith('/products');
    const isLeafCategory = isCategoryPage && !category.children?.length;

    const keys = [];
    if (hasFeature(featureSet, 'seo_indexable_filter_material')) {
        keys.push('materials');
    }
    if (hasFeature(featureSet, 'seo_indexable_filter_colour')) {
        keys.push('colors');
    }
    if (hasFeature(featureSet, 'seo_indexable_filter_location')) {
        keys.push('location');
    }

    return isLeafCategory || !isProductCategory ? keys : [];
};

const convertCategoryFilter = (state: CategoryFilterState): URLParams => {
    const categoryLink = state.selectedCategory?.id ?? state.category.id;
    if (!categoryLink || categoryLink === state.category.id) {
        return {};
    } else {
        return { categoryLink };
    }
};

const convertCheckboxFilter = (
    filterKey: string,
    state: CheckboxFilterState
): URLParams => {
    if (!state.checked) {
        return {};
    } else {
        return { [filterKey]: 1 };
    }
};

const convertCheckboxGroupFilter = (
    filterKey: string,
    state: CheckboxGroupFilterState
): URLParams => {
    if (!state.checkedItems?.length) {
        return {};
    }
    const values = state.checkedItems
        .filter((item) => !!item.value)
        .map((item) => item.value!);
    return { [filterKey]: values };
};

const convertAwardsFilter = (
    filterKey: string,
    state: AwardFilterState
): URLParams => {
    if (!state.checkedItems?.length) {
        return {};
    }
    if (state.checkedItems.some((item) => item.value === 'all')) {
        return { [filterKey]: ['all'] };
    }
    const values = state.checkedItems
        .filter((item) => !!item.value)
        .map((item) => item.value!);
    return { [filterKey]: values };
};

const convertRangeSelectorFilter = (
    filterKey: string,
    state: RangeSelectorFilterState
): URLParams => {
    if (!state.value) {
        return {};
    }
    const { min, max } = state.value;
    const { min: availableMin, max: availableMax } = state.range;
    if (min === availableMin && max === availableMax) {
        return {};
    }
    return { [filterKey]: `${min},${max}` };
};

const convertSearchListFilter = (
    filterKey: string,
    state: SearchListFilterState,
    subFilterKey?: string,
    encode?: boolean
): URLParams => {
    const params: URLParams = {};

    if (state.search) {
        params[`${filterKey}_search`] = `${
            subFilterKey ? `${subFilterKey}-` : ''
        }${state.search}`;
    }
    if (state.items.length && state?.items.length > DEFAULT_SEARCH_LIST_SIZE) {
        params[`${filterKey}_size`] = state.items.length;
    }
    const checkedItems = state?.selectedItems;

    if (!checkedItems?.length) {
        return params;
    }

    params[filterKey] = checkedItems.map(
        (item) =>
            `${subFilterKey ? `${subFilterKey}-` : ''}${
                encode ? encodePathValue(item.value) : item.value
            }`
    );
    return params;
};

const convertRadioGroupFilter = (
    filterKey: string,
    state: RadioGroupFilterState
): URLParams => {
    if (!state.selectedItem || !state.selectedItem.value) {
        return {};
    }
    return { [filterKey]: state.selectedItem.value };
};

const convertLocationFilter = (
    state: LocationFilterState,
    featureSet: FeatureFlags
): URLParams => {
    const params: URLParams = {};

    if (!state.selectedLocation) {
        return params;
    }

    const location = state.selectedLocation;

    const values: string[] = [];
    const availableKeys: (keyof SearchLocation)[] = [];
    ORDERED_LOCATION_KEY.forEach((key) => {
        const value = (location[key] ?? '').toLocaleLowerCase();
        // ignore lower level values if they are empty
        if (!value && !values.length) {
            return;
        }
        availableKeys.push(key);
        const paramKey = LOCATION_SEARCH_PARAM_KEYS[key];
        params[paramKey] = value ?? '';
        values.push(value);
    });

    if (
        availableKeys[0] &&
        INDEX_LOCATION_FILTER_TYPES.includes(availableKeys[0]) &&
        hasFeature(featureSet, 'seo_indexable_filter_location')
    ) {
        // convert to path params filter for city and region level location filter
        return {
            location: values.map((value) => encodePathValue(value)).join('-'),
        };
    } else {
        // convert to search params
        return params;
    }
};

const convertFilterStateToURLParams = (
    filterStates: FilterStates,
    featureSet: FeatureFlags,
    ignoreFilterKeys: (keyof FilterStates)[] = []
): URLParams => {
    let params: URLParams = {};

    Object.keys(filterStates).forEach((filterKey) => {
        if (
            [...ignoreFilterKeys, 'totalCount'].find((ik) => ik === filterKey)
        ) {
            return;
        }

        const paramKey = filterKey as Exclude<keyof FilterStates, 'totalCount'>;
        const urlParamKey = getMappingKeyName(filterKey);

        if (paramKey === 'facetOptions') {
            filterStates.facetOptions?.forEach((singleState) => {
                params = {
                    ...params,
                    ...convertFilterByState(
                        urlParamKey,
                        singleState.state,
                        featureSet,
                        singleState.id
                    ),
                };
            });
            return;
        }

        const state = filterStates[paramKey];
        if (!state) {
            return;
        }

        if (typeof state === 'string' || Array.isArray(state)) {
            params[urlParamKey] = state;
            return;
        }

        params = {
            ...params,
            ...convertFilterByState(urlParamKey, state, featureSet),
        };
    });

    return params;
};

const convertFilterByState = (
    filterKey: string,
    state:
        | CheckboxFilterState
        | CheckboxGroupFilterState
        | SearchListFilterState
        | RadioGroupFilterState
        | RangeSelectorFilterState
        | CategoryFilterState
        | LocationFilterState
        | AwardFilterState,
    featureSet: FeatureFlags,
    subFilterKey?: string
): URLParams => {
    switch (state.kind) {
        case 'CategorySelector':
            return convertCategoryFilter(state);
        case 'Checkbox':
            return convertCheckboxFilter(filterKey, state);
        case 'CheckboxGroup':
            return convertCheckboxGroupFilter(filterKey, state);
        case 'RangeSelector':
            return convertRangeSelectorFilter(filterKey, state);
        case 'SearchList':
            return convertSearchListFilter(
                filterKey,
                state,
                subFilterKey,
                filterKey === 'materials'
            );
        case 'RadioGroup':
            return convertRadioGroupFilter(filterKey, state);
        case 'Location':
            return convertLocationFilter(state, featureSet);
        case 'AwardsFilter':
            return convertAwardsFilter(filterKey, state);
        default:
            throw new Error(
                '[filter-url-helpers] convertFilterByState: unsupported filter state:' +
                    JSON.stringify(state)
            );
    }
};

export const parseURLToESQueryParams = (
    url: string,
    searchType?: ElasticSearchCategories,
    overrideParams?: URLParams,
    defaultSort?: ElasticSearchSortOptions
): {
    basePath: string;
    queryParams: ElasticSearchQueryProps;
} => {
    const { basePath, params: originalParams } = parseURL(url);
    const params = { ...originalParams, ...overrideParams };

    const queryParams: ElasticSearchQueryProps = {
        search: getString(params, SEARCH_QUERY_KEY) ?? '',
        sourceFilter: DEFAULT_SOURCE_FILTER,
        resultType: 'hits',
        size: getNumber(params, 'pageSize') || DEFAULT_PAGE_SIZE,
    };

    const page = Math.max((getNumber(params, 'page') ?? 0) - 1, 0);
    if (page) queryParams.page = page;

    const filters = createESFiltersFromURLParams(basePath, params);
    // some sort options in FE acutely are filters...
    const sortBy: ElasticSearchSortOptions = applyESSearchSort(
        url,
        filters,
        defaultSort
    );

    if (Object.keys(filters).length) queryParams.filters = filters;

    const searchIndex = searchType ?? getSearchIndex(basePath, params);
    if (searchIndex) queryParams.searchType = searchIndex;

    if (sortBy) queryParams.sortBy = sortBy;

    return { basePath, queryParams };
};

const getSearchIndex = (
    basePath: string,
    params: URLParams
): ElasticSearchCategories | undefined => {
    // get search index from category
    const category = getString(params, 'category');
    let searchIndex = SEARCH_CATEGORIES.find(
        (searchCategory) => searchCategory.category === category
    )?.index;
    if (searchIndex) {
        return searchIndex;
    }

    // get search index from the base path
    searchIndex = SEARCH_CATEGORIES.find(
        (searchCategory) =>
            searchCategory.categoryLink &&
            basePath.startsWith(searchCategory.categoryLink)
    )?.index;
    return searchIndex;
};

const createESFiltersFromURLParams = (
    basePath: string,
    params: URLParams,
    overrideProfessionalDefaultCategory = true
): ElasticSearchQueryFilters => {
    const filters: ElasticSearchQueryFilters = {};

    // get from base path
    const categoryLink = getCategoryLink(basePath, params);

    if (categoryLink) {
        filters.categoryLink = categoryLink;
    } else {
        // Display Architects as the default category for professionals
        if (
            basePath.includes('/professionals') &&
            overrideProfessionalDefaultCategory
        ) {
            filters.categoryLink =
                '/professionals/architecture-and-design/architects';
        }
    }

    addBooleanFilters(
        params,
        [
            'isPurchasable',
            'isOnSale',
            'isInStock',
            'isDeliveryAvailable',
            'isSampleAvailable',
            'hasProducts',
            'hasVideos',
            'hasAwards',
            'projectOfTheMonth',
            'invalidLink',
        ],
        filters
    );

    addRangeFilters(
        params,
        [
            'priceRange',
            'lengthRange',
            'widthRange',
            'heightRange',
            'diameterRange',
            'weightRange',
        ],
        filters
    );

    addStringArrayFilters(
        params,
        [
            'awards',
            'professionals',
            'materials',
            'libraryRanges',
            'associations',
            'professions',
            'brands',
            'designers',
            'professionalCategories',
            'downloads',
        ],
        filters,
        ['materials']
    );

    const colors = getStringArray(params, getMappingKeyName('colors'));
    if (colors) filters.colors = colors;

    const businessFocusesValue = getString(params, 'businessFocuses');
    const businessFocuses = businessFocusesValue
        ? (businessFocusesValue.split(',') as BusinessFocusType[])
        : undefined;
    if (businessFocuses) filters.businessFocuses = businessFocuses;

    const facetOptions: FacetOptionsFilterParams[] = [];
    getStringArray(params, 'facetOptions')?.forEach((value) => {
        const [facet, option] = value.split('-');
        if (!facet || !option) return;
        facetOptions.push({
            facet,
            option,
        });
    });
    if (facetOptions.length) filters.facetOptions = facetOptions;

    const projectCount = getNumber(params, 'projectCount');
    if (projectCount) filters.projectCount = projectCount;

    const searchLocation = getLocation(params);
    if (searchLocation) filters.searchLocation = searchLocation;

    return filters;
};

const getCategoryLink = (
    basePath: string,
    params: URLParams
): string | undefined => {
    let categoryLink = getString(params, 'categoryLink');
    if (categoryLink) {
        return categoryLink;
    }

    if (
        SEARCH_CATEGORIES.find(
            (searchCategory) =>
                searchCategory.categoryLink &&
                basePath.startsWith(searchCategory.categoryLink) &&
                basePath !== searchCategory.categoryLink
        )
    ) {
        return basePath;
    }

    return undefined;
};

const addBooleanFilters = (
    params: URLParams,
    filterKeys: CheckboxFilterKeys[],
    filters: ElasticSearchQueryFilters
) => {
    filterKeys.forEach((filterKey) => {
        const value = getBoolean(params, getMappingKeyName(filterKey));
        if (value) filters[filterKey] = value;
    });
};

const addRangeFilters = (
    params: URLParams,
    filterKeys: RangeFilterKeys[],
    filters: ElasticSearchQueryFilters
) => {
    filterKeys.forEach((filterKey) => {
        const value = getNumberRange(
            params,
            getMappingKeyName(filterKey),
            RANGE_DIVISORS[filterKey]
        );
        if (value) filters[filterKey] = value;
    });
};

const addStringArrayFilters = (
    params: URLParams,
    filterKeys: SearchListFilterKeys[],
    filters: ElasticSearchQueryFilters,
    decodeFilterKeys: SearchListFilterKeys[]
) => {
    filterKeys.forEach((filterKey) => {
        const value = getStringArray(params, getMappingKeyName(filterKey));

        if (value) {
            const needEncode = decodeFilterKeys.includes(filterKey);
            filters[filterKey] = needEncode
                ? value.map((sv) => decodePathValue(sv)!)
                : value;
        }
    });
};

export const getSearchLocationFromParams = (
    params: URLParams
): SearchLocation | null => {
    const locationValueStr = getString(params, 'location');
    if (!locationValueStr) {
        return null;
    }
    const values = locationValueStr.split('-').reverse();
    const location: SearchLocation = {};
    ORDERED_LOCATION_KEY.slice()
        .reverse()
        .forEach((key, index) => {
            if (values[index]) {
                location[key] = decodePathValue(values[index]);
            }
        });
    return location;
};

export const getLocation = (params: URLParams): SearchLocation | undefined => {
    let location = getSearchLocationFromParams(params);
    if (location) {
        return location;
    } else {
        // get location state from the search parameters
        const suburb = decodePathValue(
            getString(params, getMappingKeyName('suburb'))
        );
        const district = decodePathValue(
            getString(params, getMappingKeyName('district'))
        );
        const city = decodePathValue(
            getString(params, getMappingKeyName('city'))
        );
        const region = decodePathValue(
            getString(params, getMappingKeyName('region'))
        );
        const state = decodePathValue(
            getString(params, getMappingKeyName('state'))
        );
        const country = decodePathValue(
            getString(params, getMappingKeyName('country'))
        );

        if (!suburb && !district && !city && !region && !state && !country)
            return undefined;

        return { suburb, district, city, region, state, country };
    }
};

const getMappingKeyName = (key: string): string => {
    return URL_PARAM_MAP[key] ?? key;
};

const encodePathValue = (value: string): string => {
    return value.toLocaleLowerCase().replace(/\s/g, '+');
};

export const decodePathValue = (value?: string): string | undefined => {
    if (!value) return value;
    return value
        .replace(/\+/g, ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
